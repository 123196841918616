import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
	name: "Noticias",
	components: {
		Banner
	},
	data() {
		return {
			blLoading: true,
			arBreadcrumbs: [{
					text: "Início",
					href: "/"
				},
				{
					text: "Notícias"
				}
			],
			arCategorias: [],
			arNoticias: [],
			idCategoria: 0,
			rolagemMenu: false
		};
	},
	created() {
		this.listar();
		window.addEventListener("scroll", this.esconderMenuLateral);
	},
	methods: {
		getTexto(noticia) {
			if(noticia.resumo && noticia.resumo != "") {
				return noticia.resumo
			}else {
				return noticia.texto;
			}
		},
		listar() {
			this.$root.$api.get("noticia/listarPublicados").then(response => {
				this.arNoticias = response.retorno.noticias;
				this.arCategorias = response.retorno.categorias;
				this.blLoading = false;
			});
		},
		esconderMenuLateral() {
			this.rolagemMenu = window.scrollY > 100 ? "block" : "none";
		}
	},
	computed: {
		noticias() {
			if (this.idCategoria == 0) {
				return this.arNoticias;
			}

			return this.arNoticias.filter(obNoticia => {
				return obNoticia.id_categoria == this.idCategoria
			})
		}
	},
};